import Component from '@glimmer/component';
import ENV from 'portal/config/environment';
import Hellosign from 'portal/services/hellosign';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

interface HellosignEmbedArgs {
    signUrl: string;
    onFinish?: () => void;
    onSign?: () => void;
}

export default class HellosignEmbed extends Component<HellosignEmbedArgs> {
    @service
        hellosign!: Hellosign;

    @action
    didInsert(container: HTMLElement) {
        const client = this.hellosign.createClient();

        client.open(this.args.signUrl, {
            container,
            skipDomainVerification: ENV.DROPBOX_SIGN_SKIP_DOMAIN_VERIFICATION
        });

        client.on('finish', this.onFinish);
        client.on('sign', this.onSign);
    }

    @action
    onFinish() {
        if (this.args.onFinish) {
            this.args.onFinish();
        }
    }

    @action
    onSign() {
        if (this.args.onSign) {
            this.args.onSign();
        }
    }
}
