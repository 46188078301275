import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import Team from './team';
import TeamInvite from './team-invite';
import fullName from 'portal/utils/full-name';
import validatePhoneNumber from 'portal/validators/phone-number';
import validatePresenceWhenTrue from 'portal/validators/presence-when-true';
import validateTaxId from 'portal/validators/tax-id';
import { isBlank } from '@ember/utils';
import { validateDate, validatePresence } from 'ember-changeset-validations/validators';

export type UserRole = 'BASIC' | 'ADMIN';

export enum Type {
    INVESTOR = 'investor',
    LENDER = 'lender',
    NON_INVESTOR = 'non_investor',
    REAL_ESTATE_AGENT = 'real_estate_agent'
}

export default class User extends Model {
    @attr('boolean')
        acceptedTerms?: boolean;

    @attr('string')
        addressId?: string;

    @attr('string')
        auth0Id?: string;

    @attr('date')
        birthday?: Date;

    @attr('date')
        createdAt?: Date;

    @attr('string')
        email?: string;

    @attr('boolean')
        emailVerified?: boolean;

    @attr('string')
        familyName?: string;

    @attr('string')
        givenName?: string;

    @attr('boolean')
        hasSsn?: boolean;

    @attr('boolean')
        isMarried?: boolean;

    @attr('string')
        messageNotificationEmailPreference?: string;

    @attr('string')
        middleName?: string;

    @attr('string')
        name?: string;

    @attr('string')
        nickname?: string;

    @attr('boolean')
        noSsn?: boolean;

    @attr('string')
        phoneNumber?: string;

    @attr('string')
        picture?: string;

    @attr('string')
        prefix?: string;

    @attr('string')
        role?: UserRole;

    @attr('boolean')
        rePromptTerms?: boolean;

    @attr('string')
        smsNumber?: string;

    @attr('boolean')
        smsVerified?: boolean;

    @attr('date')
        spouseBirthday?: Date;

    @attr('string')
        spouseFirstName?: string;

    @attr('string')
        spouseMiddleName?: string;

    @attr('string')
        spouseLastName?: string;

    @attr('boolean')
        spouseNoSsn?: boolean;

    @attr('string')
        spousePrefix?: string;

    @attr('string')
        spouseSsn?: string;

    @attr('string')
        spouseSuffix?: string;

    @attr('string')
        spouseEmail?: string;

    @attr('string')
        ssn?: string;

    @attr('string')
        suffix?: string;

    @attr('boolean')
        usCitizen?: boolean;

    @attr('string')
        welcomeDealId?: string;

    @attr('string')
        street?: string;

    @attr('string')
        street2?: string;

    @attr('string')
        city?: string;

    @attr('string')
        state?: string;

    @attr('string')
        type?: Type;

    @attr('string')
        zipcode?: string;

    @attr('string')
        emailPreference?: string;

    @attr('string')
        phoneCallPreference?: string;

    @attr('string')
        portalMessagePreference?: string;

    @attr('string')
        textMessagePreference?: string;

    @attr('boolean')
        textMessageOptOut?: string;

    @belongsTo('user', { inverse: null })
        impersonator?: AsyncBelongsTo<User>;

    @belongsTo('team')
        team?: AsyncBelongsTo<Team>;

    @belongsTo('team-invite')
        teamInvite?: AsyncBelongsTo<TeamInvite>;

    get fullNameWithEmail(): string {
        return [this.fullName, this.email].compact().join(' - ').replace(/\s+/g, ' ').trim();
    }

    get fullName(): string {
        return fullName(this.givenName, this.middleName, this.familyName).replace(/\s+/g, ' ').trim();
    }

    get hasEmptyCommPreferences(): boolean {
        const commPrefsFields = [
            this.emailPreference,
            this.portalMessagePreference,
            this.messageNotificationEmailPreference,
            this.phoneCallPreference,
            this.textMessagePreference
        ];
        return commPrefsFields.every((field) => isBlank(field));
    }
}

export const userNameValidations = {
    givenName: validatePresence({
        presence: true,
        ignoreBlank: true,
        message: "First name can't be blank"
    }),
    familyName: validatePresence({
        presence: true,
        ignoreBlank: true,
        message: "Last name can't be blank"
    })
};

export const userPersonalDetailsValidations = Object.assign({},
    userNameValidations,
    {
        phoneNumber: validatePhoneNumber('Phone number'),
        spouseFirstName: validatePresenceWhenTrue({
            presence: true,
            key: 'isMarried'
        }),
        spouseLastName: validatePresenceWhenTrue({
            presence: true,
            key: 'isMarried'
        }),
        birthday: validateDate({
            allowBlank: true,
            message: 'Birthday must be a valid date, in the past, and after 1902',
            before: new Date(),
            after: new Date('1902-01-01')
        })
    }
);

export const userPhoneNumberOptionalValidations = Object.assign({},
    userNameValidations,
    {
        phoneNumber: validatePhoneNumber('Phone number', { allowBlank: true })
    }
);

export const userValidations = Object.assign(
    {},
    userPersonalDetailsValidations,
    {
        ssn: validateTaxId(undefined, { allowBlank: true }),
        spouseSsn: validateTaxId(undefined, {
            allowBlank: true
        }),
        street: validatePresence({
            presence: true,
            on: ['city', 'state', 'zipcode'],
            message: 'Address can’t be blank'
        }),
        city: validatePresence({
            presence: true,
            on: 'street'
        }),
        state: validatePresence({
            presence: true,
            on: 'city',
            message: 'State must be selected'
        }),
        zipcode: validatePresence({
            presence: true,
            on: 'state',
            message: 'ZIP code can’t be blank'
        })
    }
);

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        user: User;
    }
}
