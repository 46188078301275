import ActionItem, { ActionItemTemplateKeys } from 'portal/models/action-item';
import Analytics from 'portal/services/analytics';
import Component from '@glimmer/component';
import Deal from 'portal/models/deal';
import MagicLoginService from 'portal/services/magic-login';
import RouterService from '@ember/routing/router-service';
import window from 'ember-window-mock';
import { ActionItemMap, RedirectActivityRoutes } from '../action-items/component';
import { action } from '@ember/object';
import { reactUrl } from 'portal/helpers/react-url';
import { inject as service } from '@ember/service';

interface ActionItemCompletionArgs {
    previousActivity: ActionItem;
    nextActivity: ActionItem;
    deal: Deal
}

export default class ActionItemCompletion extends Component<ActionItemCompletionArgs> {
    @service
        router!: RouterService;

    @service
        magicLogin!: MagicLoginService;

    @service
    declare analytics: Analytics;

    get viewDealRoute() {
        return 'protected.deal.index.to-do';
    }

    get isMagicTokenUser() {
        return this.magicLogin.hasToken();
    }

    @action
    async onCreateAccount() {
        this.analytics.trackEvent('Magic Token User / Create Account');
        await this.magicLogin.deleteTokenForAuth0Signup();
    }

    @action
    onNext() {
        if (!this.args.nextActivity.isDeleted) {
            this.args.nextActivity.hasStarted = true;
            this.args.nextActivity.save();
        }

        const actionItemTemplateKey = Object.entries(ActionItemTemplateKeys).find((a)=> a[1] === this.args.nextActivity.templateKey)?.[1];
        const route = actionItemTemplateKey ? ActionItemMap.get(actionItemTemplateKey)?.get('route') : false;
        if (!actionItemTemplateKey || !route){
            this.router.transitionTo('protected.deal');
            return;
        }
        if (RedirectActivityRoutes.includes(route)) {
            const path = reactUrl(`deals/${this.args.deal?.id}/action-items/${this.args.nextActivity.id}`);
            const url = new URL(path);
            window.location.assign(url);
        } else {
            if (ActionItemMap.get(actionItemTemplateKey)?.has('routeRequiresActivityId')){
                this.router.transitionTo(route, this.args.nextActivity.id);
            } else {
                this.router.transitionTo(route);
            }
        }
    }

}
