import Deal from 'portal/models/deal';
import FeatureFlag from 'portal/services/feature-flag';
import RouteInfo from '@ember/routing/-private/route-info';
import Transition from '@ember/routing/-private/transition';
import window from 'ember-window-mock';
import { ActionItemTemplateKeys } from 'portal/models/action-item';
import { reactUrl } from 'portal/helpers/react-url';

const allDealsRoutes = [
    'protected.deals.complete',
    'protected.deals.in-progress'
];

const dealRoutesImplementedInNewPortal = [
    'protected.deal',
    'protected.deal.index.my-deal',
    'protected.deal.index.to-do',
    'protected.deal.index.documents',
    'protected.deal.index.messages',
    'protected.deal.index.people'
];

const activityRoutesImplementedInNewPortal = [
    'protected.deal.earnest-money.selection',
    'protected.deal.onboarding.seller-titleholder-info',
    'protected.deal.payoff-authorization',
    'protected.deal.provide-rental-information'
];

const activityRoutesActionItemTemplateKeys: Record<string, ActionItemTemplateKeys[]> = {
    'protected.deal.earnest-money.selection': [
        ActionItemTemplateKeys.ASSIGNOR_EARNEST_MONEY_METHOD,
        ActionItemTemplateKeys.BUYER_EARNEST_MONEY_METHOD
    ],
    'protected.deal.onboarding.seller-titleholder-info': [ActionItemTemplateKeys.SELLER_TITLEHOLDER_DETAILS],
    'protected.deal.payoff-authorization': [ActionItemTemplateKeys.PAYOFF_AUTHORIZATION],
    'protected.deal.provide-rental-information': [ActionItemTemplateKeys.PROVIDE_RENTAL_INFORMATION]
};

const activityRoutesFeatureFlags: Record<string, string> = {
    'protected.deal.earnest-money.selection': 'react_earnest_money_collection',
    'protected.deal.onboarding.seller-titleholder-info': 'react_seller_owner_information',
    'protected.deal.payoff-authorization': 'react_payoff_authorization',
    'protected.deal.provide-rental-information': 'react_provide_rental_information'
};

export const maybeRedirectToNewPortalBeforeModel = (transition: Transition, featureFlag: FeatureFlag) => {
    if (featureFlag.enabled('react_all_deals_page') && allDealsRoutes.includes(transition.to?.name)) {
        redirectToNewPortalAllDeals(transition.to);

        //We return a never fulfilling promise to block routing from continuing and loading unneeded models until the browser has redirected
        return new Promise(() => { return; });
    }

    const dealId = findParameter(transition.to, 'deal_id');
    if (shouldRedirectToNewPortalDeal(transition.to, featureFlag) && dealId !== undefined) {
        redirectToNewPortalDeal(transition.to, dealId);

        //We return a never fulfilling promise to block routing from continuing and loading unneeded models until the browser has redirected
        return new Promise(() => { return; });
    }

    return;
};

export const maybeRedirectToNewPortalAfterModel = (model: Deal, transition: Transition, featureFlag: FeatureFlag) => {
    const routeName = transition.to?.name;
    if (activityRoutesImplementedInNewPortal.includes(routeName)) {
        if (!featureFlag.enabled(activityRoutesFeatureFlags[routeName]!)) {
            return;
        }

        const actionItem = model?.actionItems?.find((actionItem) => {
            return activityRoutesActionItemTemplateKeys[routeName].includes(actionItem.templateKey as ActionItemTemplateKeys);
        });

        if (actionItem) {
            redirectToNewPortalActivity(transition.to, model.id, actionItem.id);

            // We return a never fulfilling promise to block routing from continuing
            // and showing the old page until the browser has redirected
            return new Promise(() => { return; });
        }
    }

    if (shouldRedirectToNewPortalDeal(transition.to, featureFlag)) {
        redirectToNewPortalDeal(transition.to, model.id);

        //We return a never fulfilling promise to block routing from continuing and showing the old page until the browser has redirected
        return new Promise(() => { return; });
    }

    return;
};

const shouldRedirectToNewPortalDeal = (route: RouteInfo, featureFlag: FeatureFlag): boolean => {
    return areNewPortalDealPagesEnabled(featureFlag) && isDealRouteImplementedInNewPortal(route);
};

const isDealRouteImplementedInNewPortal = (route: RouteInfo): boolean => {
    return dealRoutesImplementedInNewPortal.includes(route?.name);
};

const areNewPortalDealPagesEnabled = (featureFlag: FeatureFlag): boolean => {
    return featureFlag.enabled('react_deal_page');
};

const redirectToNewPortalDeal = (route: RouteInfo, dealId: string) => {
    const path = route?.name ==  'protected.deal' || route?.name == 'protected.deal.index.my-deal'
        ? reactUrl(`deals/${dealId}`)
        : reactUrl(`deals/${dealId}/${route?.localName}`);

    const url = new URL(path);
    const queryParameters = collectQueryParameters(route);
    Object.entries(queryParameters).forEach(([key, value]) => url.searchParams.append(key, value));

    window.location.assign(url);
};

const redirectToNewPortalAllDeals = (route: RouteInfo) => {
    const path = reactUrl('deals');

    const url = new URL(path);
    const queryParameters = collectQueryParameters(route);
    Object.entries(queryParameters).forEach(([key, value]) => url.searchParams.append(key, value));

    window.location.assign(url);
};

const redirectToNewPortalActivity = (route: RouteInfo, dealId: string, actionItemId: string) => {
    const path = reactUrl(`deals/${dealId}/action-items/${actionItemId}`);

    const url = new URL(path);
    const queryParameters = collectQueryParameters(route);
    Object.entries(queryParameters).forEach(([key, value]) => url.searchParams.append(key, value));

    window.location.assign(url);
};

const findParameter = (route: RouteInfo, key: string): string | undefined => {
    if (route.params[key] !== undefined) return route.params[key]!;
    else if ( route.parent !== null) return findParameter(route.parent, key);
    return undefined;
};

const collectQueryParameters = (route: RouteInfo, priorParameters: Record<string, string> = {}): Record<string, string> => {
    const filteredParams = Object.fromEntries(
        Object.entries(route.queryParams).filter(([, value]) => value !== undefined) as [string, string][]
    );

    const newParameters = { ...priorParameters, ...filteredParams };
    return route.parent !== null ? collectQueryParameters(route.parent, newParameters) : newParameters;
};
