import BaseRoute from 'portal/routes/base-route';
import EmporaServer from 'portal/services/empora-server';
import FeatureFlag from 'portal/services/feature-flag';
import RouteInfo from '@ember/routing/-private/route-info';
import RouterService from '@ember/routing/router-service';
import Transition from '@ember/routing/-private/transition';
import config from 'portal/config/environment';
import window from 'ember-window-mock';
import { reactUrl } from 'portal/helpers/react-url';
import { inject as service } from '@ember/service';

export default class Surveys extends BaseRoute {
    @service
    declare router: RouterService;

    @service
    declare emporaServer: EmporaServer;

    @service
    declare featureFlag: FeatureFlag;

    async beforeModel(transition: Transition) {
        if (this.featureFlag.enabled('react_customer_satisfaction_survey')){
            const path = reactUrl('surveys');

            const url = new URL(path);
            const queryParameters = this.collectQueryParameters(transition.to);
            Object.entries(queryParameters).forEach(([key, value]) => url.searchParams.append(key, value));

            window.location.assign(url);
        } else {
            const { survey_type, deal_id } = transition.to.queryParams;

            const url = `/core/surveys?survey_type=${survey_type}&deal_id=${deal_id}`;

            const response = await this.emporaServer.fetchData(url);

            if (!response.ok) {
                this.router.replaceWith(config.rootURL);
            }
            else {
                const data = await response.json();
                this.navigateTo(data.survey_url);
            }
        }
    }

    navigateTo(url: string) {
        window.location.assign(url);
    }

    collectQueryParameters = (route: RouteInfo, priorParameters: Record<string, string> = {}): Record<string, string> => {
        const filteredParams = Object.fromEntries(
            Object.entries(route.queryParams).filter(([, value]) => value !== undefined) as [string, string][]
        );

        const newParameters = { ...priorParameters, ...filteredParams };
        return route.parent !== null ? this.collectQueryParameters(route.parent, newParameters) : newParameters;
    };

}
